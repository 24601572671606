<template>
  <section class="generic-video" :class="getVideoClass">
    <div class="container" :class="{'no-margin':noMargin}">
      <div class="row" :class="{'no-margin':noMargin}">
        <div class="generic-video__container" :class="{'no-margin':noMargin}">
          <div class="generic-video__container-responsive">
            <div class="generic-video__inner">
              <iframe
                  :data-src="videoUrl"
                  src=""
                  :srcdoc="`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${videoUrl}?autoplay=1><img src=https://img.youtube.com/vi/${videoId}/hqdefault.jpg alt='Welcome Home'><span>▶</span></a>`"
                  title="Welcome Home"
                  class="lazyload"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    videoId: { type: String, required: false, default: null },
    noMargin: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      video: null,
    };
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
    getVideoClass: function() {
      return {
        'push-v--desktop--large push-v--tablet--large push-v--mobile--large': !this.noMargin,
        'col-12 no-margin': this.noMargin,
      };
    },
  },
  mounted() {
    const vidDefer = document.getElementsByTagName('iframe');
    for (let i=0; i<vidDefer.length; i++) {
      if (vidDefer[i].getAttribute('data-src')) {
        vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
      }
    }
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_generic-video.scss';
</style>
