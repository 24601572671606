<template>
  <div :id="!!id.length ? id : null" class="co-living-highlights row push-v--desktop--large">
    <div class="co-living-highlights__container col-10 offset-1">
      <div class="container">
        <div class="row">
          <section class="co-living-highlights--items">
            <span class="co-living-highlights--bar" />
            <article
                v-for="(item, index) in items"
                :key="index"
                class="co-living-highlights__col col-lg-6 col-sm-12 col-md-6"
                data-entrance="fade"
            >
              <div class="co-living-highlights__block push-v--mobile--xx-small">
                <h2 v-if="item.title" class="co-living-highlights__title h5--alt" v-html="item.title" />
                <div v-if="item.body" class="co-living-highlights__text text--faux" v-html="item.body" />
              </div>
            </article>
          </section>
          <section class="co-living-highlights--info">
            <KenticoImage class="co-living-highlights--info-bg" :src="backgroundImage[0].url" :alt="description" />
            <article ref="video" class="co-living-highlights--video">
              <ArticleVideo :video-id="videoId" :no-margin="true" />
            </article>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleVideo from '~/components/modules/GenericVideo.vue';
import getStatic from '~/utils/get-static';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';


export default {
  components: {
    ArticleVideo,
  },
  props: {
    id: { type: String, default: '' },
    backgroundImage: {
      type: Array,
      default() {
        return [{
          url: getStatic('/images/content/co-living-benefits/green-pattern.png'),
          description: '',
        }];
      },
    },
    videoId: { type: String, default: 'Hi0muyJvUMM' },
    description: { type: String, default: '' },
    items: {
      type: Array,
      required: false,
      default() {
        return [
          {
            title: 'Good night sleep',
            icon: {
              url: getStatic('/images/content/co-living-benefits/green-pattern.png'),
              description: '',
            },
            body: '<p>Cosy, comfy modern rooms that provide an environment for a great nights sleep</p>',
          },
        
        ];
      },
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const { video } = this.$refs;

    gsap.to(video, { 
      y: -150,
      duration: 3, 
      scrollTrigger: {
        trigger: video,      
        scrub: true,
      },
    });


  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_co-living-highlights.scss';
</style>
